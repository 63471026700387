var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        width: _vm.isMobileScreen ? "90%" : "400px",
        "show-close": false,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "template",
        { slot: "title" },
        [
          _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          _c("el-divider", { staticStyle: { margin: "6px 0", height: "2px" } }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-right" },
        [
          _c(
            "el-button",
            {
              staticClass: "_button",
              attrs: {
                size: _vm.isMobileScreen ? "small" : "default",
                type: "primary",
                round: "",
              },
              on: { click: _vm.addRecipient },
            },
            [_vm._v("Add")]
          ),
        ],
        1
      ),
      _c(
        "el-form",
        { ref: "sendEmailForm", attrs: { model: _vm.sendEmailForm } },
        [
          _c("div", { staticClass: "label" }, [_vm._v("Vessel Email")]),
          _c(
            "el-form-item",
            [
              _c(
                "el-col",
                { attrs: { span: 1 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-checkbox", {
                        attrs: {
                          disabled: _vm.sendEmailForm.vesselEmail === null,
                        },
                        model: {
                          value: _vm.sendEmailForm.sendToVessel,
                          callback: function ($$v) {
                            _vm.$set(_vm.sendEmailForm, "sendToVessel", $$v)
                          },
                          expression: "sendEmailForm.sendToVessel",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 22 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "inputItem" },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.sendEmailForm.vesselEmail,
                          callback: function ($$v) {
                            _vm.$set(_vm.sendEmailForm, "vesselEmail", $$v)
                          },
                          expression: "sendEmailForm.vesselEmail",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 1 } }),
            ],
            1
          ),
          _c("div", { staticClass: "label" }, [_vm._v("Surveyor Email")]),
          _c(
            "el-form-item",
            [
              _c(
                "el-col",
                { attrs: { span: 1 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-checkbox", {
                        attrs: {
                          disabled: _vm.sendEmailForm.surveyorEmail === null,
                        },
                        model: {
                          value: _vm.sendEmailForm.sendToSurveyor,
                          callback: function ($$v) {
                            _vm.$set(_vm.sendEmailForm, "sendToSurveyor", $$v)
                          },
                          expression: "sendEmailForm.sendToSurveyor",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 22 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "inputItem" },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.sendEmailForm.surveyorEmail,
                          callback: function ($$v) {
                            _vm.$set(_vm.sendEmailForm, "surveyorEmail", $$v)
                          },
                          expression: "sendEmailForm.surveyorEmail",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 1 } }),
            ],
            1
          ),
          _vm._l(_vm.sendEmailForm.recipients, function (recipient, index) {
            return _c(
              "el-form-item",
              { key: index },
              [
                _c(
                  "el-col",
                  { attrs: { span: 1 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "checkbox" } },
                      [
                        _c("el-checkbox", {
                          model: {
                            value: recipient.isSendBdn,
                            callback: function ($$v) {
                              _vm.$set(recipient, "isSendBdn", $$v)
                            },
                            expression: "recipient.isSendBdn",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 22 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "inputItem",
                        attrs: {
                          prop: "recipients." + index + ".email",
                          rules: _vm.GENERAL_EMAIL_RULE_REQ,
                        },
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: recipient.email,
                            callback: function ($$v) {
                              _vm.$set(recipient, "email", $$v)
                            },
                            expression: "recipient.email",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("el-col", { attrs: { span: 1 } }, [
                  _c("em", {
                    staticClass: "el-icon-delete pointer",
                    on: { click: () => _vm.removeEmail(index) },
                  }),
                ]),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "_cancelButton",
              attrs: {
                size: _vm.isMobileScreen ? "small" : "default",
                round: "",
                outline: "",
              },
              on: { click: _vm.hanleCloseDialog },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "el-button",
            {
              staticClass: "_button",
              attrs: {
                size: _vm.isMobileScreen ? "small" : "default",
                type: "primary",
                round: "",
              },
              on: { click: _vm.handleAddRecipients },
            },
            [_vm._v(_vm._s(_vm.buttonName))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
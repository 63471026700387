<template>
  <el-dialog
    :visible.sync="visible"
    :width="isMobileScreen ? '90%' : '400px'"
    :show-close="false"
    :close-on-click-modal="false"
    >
    <template slot="title">
      <div class="title">{{ title }}</div>
      <el-divider style="margin:6px 0; height:2px"></el-divider>
    </template>
    <div class="text-right">
      <el-button  :size="isMobileScreen ? 'small' : 'default'" class="_button" type="primary" @click="addRecipient" round>Add</el-button>
    </div>
    <el-form :model="sendEmailForm" ref="sendEmailForm">
      <div class="label">Vessel Email</div>
      <el-form-item>
        <el-col :span="1">
          <el-form-item >
            <el-checkbox v-model="sendEmailForm.sendToVessel" :disabled="sendEmailForm.vesselEmail === null"></el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="22">
          <el-form-item class="inputItem">
            <el-input v-model="sendEmailForm.vesselEmail" :disabled="true"/>
          </el-form-item>
        </el-col>
        <el-col :span="1"></el-col>
      </el-form-item>
      <div class="label">Surveyor Email</div>
      <el-form-item>
        <el-col :span="1">
          <el-form-item>
            <el-checkbox v-model="sendEmailForm.sendToSurveyor" :disabled="sendEmailForm.surveyorEmail === null"></el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="22">
          <el-form-item class="inputItem">
            <el-input v-model="sendEmailForm.surveyorEmail" :disabled="true"/>
          </el-form-item>
        </el-col>
        <el-col :span="1"></el-col>
      </el-form-item>

      <el-form-item v-for="(recipient,index) in sendEmailForm.recipients" :key="index">
        <el-col :span="1">
          <el-form-item prop="checkbox">
            <el-checkbox v-model="recipient.isSendBdn"></el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="22">
          <el-form-item class="inputItem"
            :prop="'recipients.' + index + '.email'"
            :rules="GENERAL_EMAIL_RULE_REQ">
            <el-input v-model="recipient.email"/>
          </el-form-item>
        </el-col>
        <el-col :span="1">
          <em class="el-icon-delete pointer" @click="() => removeEmail(index)"></em>
        </el-col>
      </el-form-item>

    </el-form>
    <span slot="footer">
      <el-button  :size="isMobileScreen ? 'small' : 'default'" class="_cancelButton" @click="hanleCloseDialog" round outline>Cancel</el-button>
      <el-button  :size="isMobileScreen ? 'small' : 'default'" class="_button" type="primary" @click="handleAddRecipients" round>{{ buttonName }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import { getRecipients, addRecipients, shareEbdnEmail } from '@/services/modules/ops.js'
import { GENERAL_EMAIL_RULE_REQ } from '@/constants'
export default {
  name: 'AddRecipientEmail',
  props: {
    entity: {
      type: Object
    }
  },
  data () {
    return {
      GENERAL_EMAIL_RULE_REQ,
      sendEmailForm: {
        sendToVessel: false,
        vesselEmail: null,
        sendToSurveyor: false,
        surveyorEmail: null,
        recipients:[]
      },
      visible: false,
      isCompleted: false,
      title: '',
      buttonName: '',
      jobId: null,
      successMessages: {
        'add': 'Add recipient successful.',
        'share': 'Share EBDN successful.'
      },
      errorMessages: {
        'add': 'Add recipient failed.',
        'share': 'Share EBDN failed.'
      },
    }
  },
  async mounted () {
    this.isCompleted = this.entity?.status === 4
    this.title = this.isCompleted ? 'Share EBDN' : 'Add Recipient'
    this.buttonName = this.isCompleted ? 'Share' : 'Save'
    this.jobId = this.entity?.job?.id ?? this.entity.id

    if (this.entity?.job?.id) {
      this.sendEmailForm.vesselEmail = this.entity.job?.vesselEmail ?? null
      this.sendEmailForm.surveyorEmail = this.entity.job?.surveyorEmail ?? null
    } else {
      this.sendEmailForm.vesselEmail = this.entity?.vesselEmail ?? null
      this.sendEmailForm.surveyorEmail = this.entity?.surveyorEmail ?? null
    }

    this.sendEmailForm.sendToVessel = this.sendEmailForm.vesselEmail ? true : false
    this.sendEmailForm.sendToSurveyor = this.sendEmailForm.surveyorEmail ? true : false

    await this.getRecipientList()
    this.visible = true
  },
  methods: {
    async getRecipientList() {
      await getRecipients(this.jobId).then(res => {
        if (res.code === 1000 && res.data) {
          let recipients = res.data
          this.sendEmailForm.recipients = recipients.filter(this.filterDefaultRecipients)
          this.overwriteDefaultRecipients(recipients)
        }
      })
    },
    async sendAddRecipients() {
      let data = [...this.getDefaultRecipients(), ...this.sendEmailForm.recipients]
      await addRecipients(this.jobId, data).then(res => {
        if (res.code === 1000) {
          this.$message({ type: 'success', message: this.successMessages.add })
          this.hanleCloseDialog()
        } else {
          this.$message({ type: 'error', message: this.errorMessages.add})
        }
      })
    },
    async shareEBDN() {
      let data = [...this.getDefaultRecipients(), ...this.sendEmailForm.recipients]
      await shareEbdnEmail(this.jobId, data).then(res => {
        if (res.code === 1000) {
          this.$message({ type: 'success', message: this.successMessages.share })
          this.hanleCloseDialog()
        } else {
          this.$message({ type: 'error', message: this.errorMessages.share})
        }
      })
    },
    getDefaultRecipients() {
      const vesselEmail = this.sendEmailForm.vesselEmail
      const sendToVessel = this.sendEmailForm.sendToVessel
      let vessel = []
      if( vesselEmail ) {
        vessel.push({ isSendBdn: sendToVessel, email: vesselEmail })
      }

      const surveyorEmail = this.sendEmailForm.surveyorEmail
      const sendToSurveyor = this.sendEmailForm.sendToSurveyor
      let surveyor = []
      if( surveyorEmail ) {
        surveyor.push({ isSendBdn: sendToSurveyor, email: surveyorEmail })
      }

      return [ ...vessel, ...surveyor]
    },
    filterDefaultRecipients( item ) {
      const vesselEmail = this.sendEmailForm.vesselEmail
      const surveyorEmail = this.sendEmailForm.surveyorEmail
      return item.email !== vesselEmail && item.email !== surveyorEmail
    },
    overwriteDefaultRecipients( recipients ) {
      const vesselEmail = this.sendEmailForm.vesselEmail
      const vessel = recipients.find( item => item.email === vesselEmail )
      if( vessel ) {
        this.sendEmailForm.sendToVessel = vessel.isSendBdn
      }

      const surveyorEmail = this.sendEmailForm.surveyorEmail
      const surveyor = recipients.find( item => item.email === surveyorEmail )
      if( surveyor ) {
        this.sendEmailForm.sendToSurveyor = surveyor.isSendBdn
      }

    },
    handleAddRecipients () {
      this.$refs.sendEmailForm.validate((valid) => {
        if (valid) {
          this.isCompleted ? this.shareEBDN() : this.sendAddRecipients()
        }
      })
    },
    hanleCloseDialog () {
      this.$refs.sendEmailForm.resetFields()
      this.visible = false
      this.$emit('close')
    },
    addRecipient() {
      this.sendEmailForm.recipients.push({ isSendBdn: false, email: null })
    },
    removeEmail( index ) {
      this.sendEmailForm.recipients.splice( index, 1 )
    },
  },
  computed: {
    ...mapState({
      isMobileScreen: "isMobileScreen",
    }),
  }
}
</script>

<style lang="scss" scoped>
.el-divider--horizontal{
  margin:2px 0;
  height:2px
}
.el-divider{
  background-color: #dcdfe6bd;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #1D65C1 !important;
}
/deep/.el-dialog__body{
  padding:20px !important;
}
.el-form-item{
  margin-bottom: 0;
}
/deep/.el-checkbox__inner{
  height:16px;
  width: 16px;
}
.title {
  color: #1D65C1;
  font-weight: 600;
}
.text-right {
  text-align: right;
}
.label {
  text-indent: 20px;
}
.inputItem {
  margin: 0px 5px;
}
.pointer {
  cursor: pointer;
}
._button {
  border-color: #1D65C1;
  font-size:12px;
  font-weight: 500;
  background-color:#1D65C1;
  padding:8px 22px;
}
._cancelButton {
  border: 2px solid #1D65C1;
  font-size:12px;
  font-weight: 500;
  color: #1D65C1;
  padding:8px 22px;
}

</style>
